import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slide from '@material-ui/core/Slide';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { SearchInput } from '../searchInput';
import { CloseOutlined, HistoryOutlined } from '@mui/icons-material';
import { useStyles } from './styles';
import { useSearchCase } from 'context/SearchCase';


export function ModalSearch() {
	const { listCases, loading, setCases, handleFetchCases, checked, setChecked } = useSearchCase();
	const searchRef = React.useRef();
	const [ history, setHistory ] = React.useState( [] );
	const classes = useStyles( { checked } );

	React.useEffect( () => {
		let list = [];
		if ( localStorage.getItem( 'search' ) ) {
			list = JSON.parse( localStorage.getItem( 'search' ) );
			setHistory( list );
		}
		return () => {
			setCases( null );
		};
	},[ setCases ] );


	const handleClick = ( item ) => {
		let lista = localStorage.getItem( 'search' ) ? JSON.parse( localStorage.getItem( 'search' ) ) : [];
		lista = lista.filter( ( { case: { id } } ) => id !== item.case.id );
		lista.unshift( item );
		localStorage.setItem( 'search', JSON.stringify( lista.slice( 0,3 ) ) );
		setChecked( false );
	};

	const handleRemove = ( item ) => {
		const lista = localStorage.getItem( 'search' ) ? JSON.parse( localStorage.getItem( 'search' ) ) : [];
		const newList = lista.filter( ( { case: { id } } ) => id !== item.case.id );
		setHistory( newList );
		localStorage.setItem( 'search', JSON.stringify( newList ) );
	};

	return (
		<div className={classes.container}>
			<Slide direction="right" in={checked} mountOnEnter unmountOnExit>
				<Paper elevation={4} className={classes.paper}>
					<ClickAwayListener onClickAway={() => setChecked( false )}>
						<div className={classes.box}>
							<div className={classes.boxInput}>
								<SearchInput
									loading={loading}
									onKeyDown={( { key } ) => key === 'Enter' && handleFetchCases( searchRef.current )}
									searchRef={searchRef} />
							</div>
							{listCases?.map( item => (
								<div key={item.case.id} className={classes.itemHistory}>
									<Link to={`/app/casos/${item.case.id}?tab=process`} onClick={() => handleClick( item )} >{item.case.internalId}, { item.author.name }, {item.process.number}, {item.process.clientFolder}</Link>
								</div>
							) )}
							{listCases?.length === 0 && (
								<p>Nenhum caso encontrado</p>
							)}
							<p>Recentes</p>
							{history?.map( item => (
								<div key={item.case.id} className={classes.itemHistory}>
									<HistoryOutlined /><Link to={`/app/casos/${item.case.id}?tab=process`} onClick={() => handleClick( item )} >{item.case.internalId}, { item.author.name }, {item.process.number}</Link><button onClick={() => handleRemove( item )} className={classes.buttonDelete}><CloseOutlined /></button>
								</div>
							) )}

							<div className={classes.paperFooter}><span>Para abrir a pesquisa utilize</span> <div className={classes.boxKeyBoard}>Ctrl</div> + <div className={classes.boxKeyBoard}>b</div></div>
						</div>
					</ClickAwayListener>
				</Paper>
			</Slide>
		</div>
	);
}
