import axios from 'axios';
import { verifyEnvUrl } from '../../util/verifyEnvUrl';

const PROD_FAST_SEARCH_URL = 'https://api.acordofechado.com.br/fast-search';
const DEV_FAST_SEARCH_URL = 'https://api.acordofechado.com.br/fast-search-dev';
// const DEV_FAST_SEARCH_URL = 'http://localhost:8080';

const fastSearchApi = axios.create( {
	baseURL: verifyEnvUrl( PROD_FAST_SEARCH_URL, DEV_FAST_SEARCH_URL ),
} );

fastSearchApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default fastSearchApi;
