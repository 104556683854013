import jwt from 'jsonwebtoken';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales';
numeral.locale( 'pt-br' );

export const allStatus = props => {
	return props.map( e => ( {
		...e,
		name     : e.status,
		selected : false,
		checked  : false
	} ) );
};

export const allTasks = props => {
	let task = [
		{
			id         : 9999,
			name       : '',
			status     : '',
			dealStatus : '',
			task       : '',
			selected   : false,
			checked    : false
		}
	];

	props.dealStatusList.map( e =>
		task.push( { ...e, name: e.task, selected: false, checked: false } )
	);

	const idsSt = [ ...new Set( task.map( x => x.task ) ) ];
	const tasks = idsSt.map( k => task.filter( b => b.task === k ).pop() );

	return tasks;
};

export const allClient = props => {

	const clientsMap = props.clients?.data || props.clients;
	let client = [];

	clientsMap.map( e => client.push( { name: e.name, id: e.id } ) );

	const name = [ ...new Set( client.map( x => x.name ) ) ];
	return name
		.sort( ( a, b ) => a.localeCompare( b ) )
		.map( k => client.filter( b => b.name === k ).pop() );
};

export const allSubClient = props => {
	let subClient = [];
	props.subClients.map( e => subClient.push( { id: e.id, name: e.name, clientId: e.client_id } ) );

	return subClient
		.sort( ( a, b ) => a.name.localeCompare( b.name ) );
};

export const allGroups = props => {
	let group = [];
	props.groups.map( e => group.push( { id: e.id, name: e.name, sub_client_id: e.sub_client_id, negotiateInAf: e.negotiateInAf } ) );

	return group
		.sort( ( a, b ) => a.name.localeCompare( b.name ) );
};

export const allSubClientDashboard = props => {
	let client = [];
	props.subClients.map( e => client.push( { name: e.name, clientId: e.sub_client.client_id } ) );

	const name = [ ...new Set( client.map( x => x.name ) ) ];
	return name
		.sort( ( a, b ) => a.localeCompare( b ) )
		.map( k => client.filter( b => b.name === k ).pop() );
};

export const formatDate = d => {
	return moment( d, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
};

export const formatDatePt = d => {
	return moment( d, 'DD/MM/YYYY' ).format( 'YYYY-MM-DD' );
};

export const getIconsTask = e => {
	switch ( e.toLowerCase().trim() ) {
	case 'pegar contato':
		return { bg: '#fff', icon: { name: 'ImportContacts', bg: '#05aecc' } };
	case 'pegar dados':
		return { bg: '#fff', icon: { name: 'ImportContacts', bg: '#05aecc' } };
	case 'tratar contraproposta':
		return { bg: '#fff', icon: { name: 'MailOutlined', bg: '#ff9f19' } };
	case 'tratar whatsapp':
		return { bg: '#fff', icon: { name: 'WhatsApp', bg: '#0cb969' } };
	case 'assinar minuta':
		return { bg: '#fff', icon: { name: 'BorderColorOutlinedIcon', bg: '#0cb969' } };
	case 'cobrar minuta':
		return { bg: '#fff', icon: { name: 'ListAlt', bg: '#eb5a46' } };
	case 'validar minuta':
		return { bg: '#fff', icon: { name: 'PlaylistAddCheck', bg: '#1781aa' } };
	case 'ligar':
		return {
			bg   : '#fff',
			icon : { name: 'HeadsetMicOutlined', bg: '#034e79' }
		};
	default:
		return { bg: '#fff', icon: { name: 'HelpOutline', bg: '#c2c3c3' } };
	}
};

export const getMaxProposal = value => {
	return value[value.length - 1];
};

export const removeSimbol = value => {
	// console.log(value)
	if ( typeof value === 'string' ) {
		let newValue = value.replace( 'R$', '' );
		newValue = newValue.replace( '.', '' );

		return newValue;
	}
	return '';
};

export const getLawyer = ( { cases, lawyerId } ) => {
	if ( cases ) {
		let processes = cases.process?.processes.sort(
			( a, b ) => new Date( b.updatedAt ) - new Date( a.updatedAt )
		);
		if ( lawyerId ) {
			return processes.find( lawyer => lawyer.lawyer_id === lawyerId )?.lawyer;
		}
		return processes.find( lawyer => lawyer.lawyer_id !== null )?.lawyer;
	}
};

export const getLawyers = cases => {
	if ( cases ) {
		const lawyer = [];
		cases.process.processes.map(
			process => process?.lawyer && lawyer.push( process.lawyer )
		);

		return lawyer;
	}
};

export const logout = unauthorized => {
	if ( unauthorized === 'unauthorized' ) {
		localStorage.removeItem( 'af-token' );
		localStorage.removeItem( 'user_id' );
		localStorage.removeItem( 'af-user' );
		localStorage.removeItem( 'redirect-url' );
		localStorage.removeItem( 'token-experip' );
		localStorage.removeItem( 'experip-token-error' );
		window.location.href = '/signin';
	}
};


export const getTokenLogin = () => {
	return localStorage.getItem( 'af-token' );
};

export const checkIsExpiredLoginToken = () => {
	const { iat, idProfileType } = jwt.decode( getTokenLogin(), 'secretkey' );

	const now = Date.now();

	const diffInMs = now - ( iat * 1000 );
	const diffInDays = Math.round( diffInMs / 86400000 );

	const days = 3;
	if ( diffInDays > days || !idProfileType ) {
		logout( 'unauthorized' );
	}
};
