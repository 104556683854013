import {
	showSettingsLoader,
	showModalMessage
} from 'actions/Setting';
import { cases as cases_api } from 'api/cases';
import { put, fork, call, all, takeEvery, select } from 'redux-saga/effects';
import { fetchCases } from '../actions';

function* fetch_cases_reducer() {
	const { cases } = yield select();

	return cases;
}

function* update_cases_submit( { payload } ) {

	const { cases } = yield select();

	try {
		yield put( showSettingsLoader( true ) );
		const response = yield cases_api.massEdit( payload.payload );
		if ( response.status === 200 ) {
			yield put( { type: 'EDIT_CASES', payload: false } );

			yield put(
				showModalMessage( {
					show    : true,
					title   : '',
					message : 'Casos alterados com sucesso'
				} )
			);

			yield put( showSettingsLoader( false ) );
		}
	} catch ( error ) {
		console.log( error );

		if ( error instanceof Error ) {
			yield put( { type: 'EDIT_CASES', payload: false } );

			yield put(
				showModalMessage( {
					show    : true,
					title   : 'Ocorreu um erro',
					message : error.message
				} )
			);

			yield put( showSettingsLoader( false ) );
		}
	}
	if ( payload?.from !== 'batch-change' ) {
		yield put( fetchCases( cases.filters ) );
	}
}

function* mass_edit_case_set_reducer( { payload } ) {
	const cases = yield call( fetch_cases_reducer );
	const { auth } = yield select();

	let { submit, listFilters } = cases;

	const { value, id, name, field } = payload;

	const [ situation ] = listFilters.dealStatus.filter(
		e => e.name.indexOf( value ) !== -1
	);

	const update = {
		clientCounterProposalValue: () => {
			function isValue( oldValue, newValue ) {
				return value !== '0,00' ? newValue : oldValue;
			}

			return submit.map( sb =>
				sb.case.id === id
					? {
						...sb,
						case: {
							...sb.case,
							status: situation?.status || sb.case.status
						},
						date: {
							origin  : 'console-batch-editCase',
							by_user : auth.userLogin
						},
						[name]: {
							...sb[name],
							[field]      : value,
							proposals    : isValue( sb.deal.proposals, [ value ] ),
							lastProposal : isValue( sb.deal.lastProposal, value ),
							edited       : true
						}
					}
					: { ...sb, date: {
						origin  : 'console-batch-editCase',
						by_user : auth.userLogin
					}, }
			);
		},
		default: () => {
			return submit.map( sb =>
				sb.case.id === id
					? {
						...sb,
						case: {
							...sb.case,
							status: situation?.status || sb.case.status
						},
						date: {
							origin  : 'console-batch-editCase',
							by_user : auth.userLogin
						},
						[name]: {
							...sb[name],
							[field] : value,
							edited  : true
						}
					}
					: { ...sb, date: {
						origin  : 'console-batch-editCase',
						by_user : auth.userLogin
					}, }
			);
		}
	};

	let fn = '';
	if ( update[payload.field] ) {
		fn = update[payload.field];
	} else {
		fn = update['default'];
	}

	yield put( {
		type    : 'EDIT_MASS_CASE',
		payload : fn()
	} );
}

export function* mass_edit_observer() {
	yield takeEvery( 'MASS_EDIT_SAGA', mass_edit_case_set_reducer );
}

export function* update_cases_observer() {
	yield takeEvery( 'UPDATE_CASES_SAGA', update_cases_submit );
}

export default function* rootSaga() {
	yield all( [
		fork( mass_edit_observer ),
		fork( update_cases_observer )
	] );
}
